import './Registration.css';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Axios from 'axios'

const theme = createTheme();

function Registration() {

    const [regEmail, setRegEmail] = useState("");
    const [regPassword, setRegPassword] = useState("");
    const [error, setError] = useState("");
    const [disabled, setDisabled] = useState(false);

    const handleRegister = (e) => {
        e.preventDefault();

        if(disabled){
            return;
        }

        if (!regEmail.includes("@stxavier")) {
            setError("You must enter an email address licensed under the @stxavier domain.")
            return false;
        } else if (regPassword.length < 8) {
            setError("You must enter a password 8 characters or longer.")
            return false;
        }

        Axios.post(process.env.REACT_APP_API_URL + "/api/registerUser", { email: regEmail, password: regPassword }).then((response) => {
            console.log(response.data)
            if (response.data.includes("You have been successfully registered!")) {
                setDisabled(true);
                setError("");
                document.getElementById("success").innerHTML = response.data;
            } else {
                setError(response.data);
            }
        });
    }

    return (
        <div className="submissionOuterContainer">
            <Typography component="h1" variant="h5">
                Register
            </Typography>
            <Box component="form" onSubmit={handleRegister} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(e) => {
                        setRegEmail(e.target.value);
                    }}
                    error={error !== "" ? true : false}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => {
                        setRegPassword(e.target.value);
                    }}
                    error={error !== "" ? true : false}
                    helperText={error}
                />
                {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
                <Typography component="p" variant="p">
                    To create a secure password, we suggest including a mix of capital and lowercase letters as well as numbers and symbols. Passwords must be 8 characters long.
                </Typography>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={disabled}
                >
                    Register
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Typography component="p" variant="p" id="success"></Typography>
                        <Link to="/resendConfirmation">
                Didn't get confirmation email?
              </Link>
                    </Grid>
                    <Grid item>
                        {/* <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link> */}
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default Registration;
