import Login from "./modules/Login/Login"
import Confirmation from "./modules/Confirmation/Confirmation"
import Registration from "./modules/Registration/Registration"
import Upload from "./modules/Upload/Upload"
import Videos from "./modules/Videos/Videos"
import Player from "./modules/Player/Player"
import Landing from "./modules/Landing/Landing"
import Admin from "./modules/Admin/Admin"

import logo from './images/logo.png'

import Cookies from 'js-cookie'

import './App.css'

import {
  Routes,
  Route,
  Link,
  Navigate
} from "react-router-dom";

import React, { useState, useEffect } from 'react';

import Axios from 'axios'
import PasswordReset from "./modules/PasswordReset/PasswordReset"
import PasswordEmail from "./modules/PasswordEmail/PasswordEmail"
import ResendConfirmation from "./modules/ResendConfirmation/ResendConfirmation"

function App() {

  const [userEmail, setUserEmail] = useState("");
  const [admin, setAdmin] = useState(false);


  const authenticate = () => {
    Axios.get(process.env.REACT_APP_API_URL + "/api/auth", {
      withCredentials: true, 
    }).then((response) => {
      if (response.data.includes("User authenticated as")) {
        setUserEmail(response.data.split(":")[1].split("@")[0] + "!");
        setAdmin(response.data.split(":")[2]);
        return true;
      } else {
        return false;
      }
    });
  }

  const logout = () => {
    Axios.get(process.env.REACT_APP_API_URL + "/api/logout", {
      withCredentials: true, 
    }).then(() => {
      window.location.reload(true);
    });

  }

  useEffect(() => {
    authenticate();
  }, []);

  return (
    <div className="routeContainer">

      <ul className="navbarList">
        <div className="navbarSides">
        <Link style={{fontSize: 25}} style={{height: "100%"}} className="navbarLinks" to="/"><img style={{height: "100%"}} src={logo}/></Link>
          <li><Link style={{fontSize: 25, fontWeight: 700}} className="navbarLinks" to="/"><span style={{ color: "#56ccf2"}}>Tutor</span><span style={{ color: "#2f80ed"}}>Y'all</span></Link></li>
          <li><Link className="navbarLinks" to="/videos">Videos</Link></li>

        </div>


        <div className="navbarSides">
          {
            userEmail !== "" ?
            <React.Fragment>
              <li><Link className="navbarLinks" to="/upload">Upload</Link></li>
              <li className="logoutHover"><Link className="navbarLinks" to={ admin === "admin" ? "/admin" : "/videos" }>Welcome <span className="profileText">{userEmail}</span></Link><button onClick={logout} className="logout defaultButton">Logout</button></li>
              </React.Fragment>
              :
              <React.Fragment>
                <li><Link className="navbarLinks" to="/register">Register</Link></li>
                <li><div className="loginBoxHighlight"><Link className="navbarLinks" to="/login">Login</Link></div></li>
              </React.Fragment>
          }
        </div>


      </ul>
      <div className="componentContainer">
        <Routes >
          {
            userEmail !== "" ? 
            <React.Fragment>
            <Route path="/upload" element={<Upload userEmail={userEmail}/>} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/" element={<Videos />} />
            </React.Fragment>
            :
            <React.Fragment>
            <Route path="/register" element={<Registration />} />
            <Route path="/login" element={<Login />} />
            <Route path="/resetPassword/:resetCode" element={<PasswordReset />} />
            <Route path="/resendConfirmation" element={<ResendConfirmation />} />
            <Route path="/resetPassword" element={<PasswordEmail />} />
            <Route path="/" element={<Landing />} />
            </React.Fragment>
          }
          
          <Route path="/videos" element={<Videos />} />
          <Route path="/videos/play" element={<Player />} />
          <Route path="/confirm/:confirmationCode" element={<Confirmation />} />
          <Route
        path="*"
        element={<Navigate to="/videos" />}
    />
        </Routes>
      </div>

    </div>
  );
}

export default App;