import './Landing.css';
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import {
  Link as RouteLink
} from "react-router-dom";

import become_tutor from '../../images/become_tutor.svg'
import concise_pointed_content from '../../images/concise_pointed_content.svg'
import one_stop_shop from '../../images/one_stop_shop.svg'
import student_checked from '../../images/student_checked.svg'
import ask from '../../images/ask.svg'


function Landing() {

  return (
    <Container maxWidth="100%" disableGutters={true}>
      <Paper
        sx={{
          position: 'relative',
          borderRadius: 0,
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 0,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: "90vh",
          backgroundImage: `url("https://howtocleanthings.com/wp-content/uploads/2018/03/surfaces_chalkboard-768x512.jpg");`,
        }}
      >
        {/* Increase the priority of the hero background image */}
        {<img style={{ display: 'none' }} src={"https://howtocleanthings.com/wp-content/uploads/2018/03/surfaces_chalkboard-768x512.jpg"} alt="background image" />}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <Grid container>
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 10 },
              }}
            >
              <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                We're revolutionizing the learning process.
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                The days of scouring the internet for information and still not understanding are over. Meet <span style={{ color: "#56ccf2"}}>Tutor</span><span style={{ color: "#2f80ed"}}>Y'all</span>.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6}></Grid>
        </Grid>
      </Paper>






      <Box
        sx={{
          backgroundColor: '#EEEEEE',
          textAlign: "center",
          position: 'relative',
          width: "100%",
        }}
      >
        <Grid container sx={{ p: 5 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                position: 'relative',
                display: "flex",
                textAlign: "left",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography component="h1" variant="h4" color="inherit" sx={{ pt: 2 }} gutterBottom>Learning can be confusing. We understand that more than <em>anyone.</em></Typography>

              <Typography component="h1" variant="h5" color="inherit" gutterBottom>As a community of students, we are making the learning process easier and more efficient.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>






      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: "center",
          position: 'relative',
          width: "100%",
        }}
      >
        <Typography component="h1" variant="h4" color="inherit" sx={{ pt: 6 }}>The <span style={{ color: "#56ccf2"}}>Tutor</span><span style={{ color: "#2f80ed"}}>Y'all</span> Advantage</Typography>
        <Grid container>
          <Grid item xs={4} md={4}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 3 },
              }}
            >
              <img style={{height: "20vw"}} className="imageSuppText" src={concise_pointed_content} />

              <Typography component="h1" variant="h5" color="inherit" gutterBottom>Concise, Pointed Content</Typography>
              <Typography component="p" variant="p" color="inherit" gutterBottom>Student-created content reinforces the core material taught in class and presents it in an easily digestable short video format.</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 3 },
              }}
            >
              <img style={{height: "20vw"}} className="imageSuppText" src={one_stop_shop} />

              <Typography component="h1" variant="h5" color="inherit" gutterBottom>One-Stop-Shop for Help</Typography>
              <Typography component="p" variant="p" color="inherit" gutterBottom>No more need for scouring the internet for elusive resources. <span style={{ color: "#56ccf2"}}>Tutor</span><span style={{ color: "#2f80ed"}}>Y'all</span> provides help in one centralized platform.</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 3 },
              }}
            >
              <img style={{height: "20vw"}} className="imageSuppText" src={student_checked} />
              <Typography component="h1" variant="h5" color="inherit" gutterBottom>Student-Checked Material</Typography>
              <Typography component="p" variant="p" color="inherit" gutterBottom>A panel of selected students check uploaded content for correct to ensure that you view only correct materials.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>




      <Box
        sx={{
          backgroundColor: '#EEEEEE',
          textAlign: "center",
          position: 'relative',
          width: "100%",
        }}
      >
        <Typography component="h1" variant="h4" color="inherit" sx={{ pt: 8 }}>Why <span style={{ color: "#56ccf2"}}>Tutor</span><span style={{ color: "#2f80ed"}}>Y'all</span> Works</Typography>
        <Grid container sx={{ p: 5 }}>
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 10 },
              }}
            >
              <Typography style={{ width: '100%' }} component="h1" variant="h4" color="inherit" gutterBottom>Learning From Current Students</Typography>
              <Typography sx={{ width: "100%" }} component="p" variant="h6" color="inherit" gutterBottom>Content on <span style={{ color: "#56ccf2"}}>Tutor</span><span style={{ color: "#2f80ed"}}>Y'all</span> is made by students who have taken the class or are taking the class. This ensures that the content you watch is the same as what you need to know.</Typography>

            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 10 },
                borderRadius: "20px",
                background: "linear-gradient(to right, #56ccf2, #2f80ed);"
              }}
            >
              <Typography style={{ width: '100%' }} component="h1" variant="h4" color="white" gutterBottom>Average Increase on Tests:</Typography>
              <Typography sx={{ width: "100%",  color: "white"}} component="p" variant="h1" color="inherit" gutterBottom>6 points</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>






      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: "center",
          position: 'relative',
          width: "100%",
        }}
      >
        <Typography component="h1" variant="h4" color="inherit" sx={{ pt: 8 }}>Join <span style={{ color: "#56ccf2"}}>Tutor</span><span style={{ color: "#2f80ed"}}>Y'all</span></Typography>
        <Grid container sx={{ pl: 5, pr: 5, pb: 5, pt: 0, }}>
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                position: 'relative',
                p: 10,
              }}
            >
              <img className="imageSuppText" src={become_tutor} />
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                position: 'relative',
                display: "flex",
                textAlign: "left",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                pl: 5,
                pr: 5
              }}
            >
              <Typography style={{ width: '100%' }} component="h1" variant="h5" color="inherit" gutterBottom>Become a Tutor</Typography>
              <Typography sx={{ width: "100%" }} component="p" variant="p" color="inherit" gutterBottom>Cement your own learning though teaching your peers. Create content at your own pace and time.
</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>






      <Box
        sx={{
          backgroundColor: '#eeeeee',
          textAlign: "center",
          position: 'relative',
          width: "100%",
        }}
      >
        <Grid container sx={{ p: 5 }}>
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                position: 'relative',
                display: "flex",
                textAlign: "left",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                pl: 5,
                pr: 5
              }}
            >
              <Typography style={{ width: '100%' }} component="h1" variant="h5" color="inherit" gutterBottom>Question or Suggestion? We'd LOVE to Hear From You.</Typography>
              <Typography style={{ width: '100%' }} component="p" variant="p" color="inherit" gutterBottom>Contact us if you have any suggestions, need help, or want to request a video. We're all ears.</Typography>
              <Link style={{ width: '100%' }} href="https://us20.list-manage.com/contact-form?u=253b81090a06320c1c6fbd20c&form_id=9041736da09eb3caee575c179d13c7ad" underline="hover" target="_blank">Click To Go To Suggestion Form</Link>
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                position: 'relative',
                p: 10,
              }}
            >
              <img className="imageSuppText" src={ask} />
            </Box>
          </Grid>
        </Grid>
      </Box>









      <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
        <Container maxWidth="lg">

          <Typography variant="h6" align="center" gutterBottom>
          <span style={{ color: "#56ccf2"}}>Tutor</span><span style={{ color: "#2f80ed"}}>Y'all</span>
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="text.secondary"
            component="p"
          >
            Changing how students learn since 2022.
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://localhost:3000">
            <span style={{ color: "#56ccf2"}}>Tutor</span><span style={{ color: "#2f80ed"}}>Y'all</span>
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Container>
      </Box>
    </Container>
  );
}

export default Landing;
