import './Confirmation.css';
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

import Axios from 'axios'


function Confirmation() {

  const handleConfirmation = () => {
    Axios.post(process.env.REACT_APP_API_URL + "/api/confirm/:confirmationCode", { token: window.location.href.substring(window.location.href.lastIndexOf('/') + 1) }).then((response) => {
      document.getElementById("message").innerHTML = response.data;
    });
  }

  useEffect(() => {
    handleConfirmation();
  });

  return (
      <div className="submissionOuterContainer">
        <Typography id="message" component="h1" variant="h5"></Typography>
      </div>
  );
}

export default Confirmation;
