import './Upload.css';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';

import Axios from 'axios'

const theme = createTheme();

function Upload(props) {

  const [videoTitle, setVideoTitle] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(undefined);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({id: 0, label: "Select a Subject"});


  useEffect(() => {
    getSubjects();
  }, []);

  const getSubjects = () => {
    Axios.get(process.env.REACT_APP_API_URL + "/api/getSubjects").then((response) => {
      let tempArray = [{id: 0, label: "Select a Subject"}];
      for (var i = 0; i < response.data.length; i++) {
        const newObject = {};
        delete Object.assign(newObject, response.data[i], { ['label']: response.data[i]['name'] })['name'];
        tempArray.push(newObject);
      }
      setSubjects(tempArray);
    });
  }

  const handleUpload = (e) => {
    e.preventDefault();

    console.log(selectedSubject);

    if (!videoLink.includes("/watch?v=")) {
      setError("Please enter a valid youtube URL, in the default \"watch?v=\" format, i.e. https://www.youtube.com/watch?v=bUGyonHPkBo");
      return;
    } else if(selectedSubject === null || selectedSubject.id === 0) {
      setError("Please select a subject.");
      return;
    }

    Axios.post(process.env.REACT_APP_API_URL + "/api/insert", { videoTitle: videoTitle, videoLink: videoLink, description: description, userEmail: props.userEmail, subject: selectedSubject.label}).then((response) => {
      if (response.data.includes("Video successfully uploaded!")) {
        setDisabled(true);
        setError("");
        document.getElementById("success").innerHTML = response.data;
      } else {
        setError(response.data);
      }
    });
  }

  return (
    <div className="submissionOuterContainer">
      <Typography component="h1" variant="h5">
        Upload
      </Typography>
      <Box component="form" onSubmit={handleUpload} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Title"
          name="title"
          autoComplete="title"
          autoFocus
          onChange={(e) => {
            setVideoTitle(e.target.value);
          }}
          error={error !== "" ? true : false}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="Youtube URL"
          label="Youtube URL"
          type="Youtube URL"
          id="Youtube URL"
          autoComplete="Youtube URL"
          onChange={(e) => {
            setVideoLink(e.target.value);
          }}
          error={error !== "" ? true : false}
          helperText={error}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          multiline
          name="Description"
          label="Description"
          type="Description"
          id="Description"
          autoComplete="Youtube URL"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          error={error !== "" ? true : false}
          helperText={error}
        />
        <Autocomplete
        isOptionEqualToValue={(option, value) => option.id === value.id}
          disablePortal
          options={subjects}
          sx={{ width: 300 }}
          value={selectedSubject}
          onChange={(e, newValue) => {
            setSelectedSubject(newValue);
          }}
          renderInput={(params) => <TextField {...params} 
            error={error !== "" ? true : false}
            helperText={error} label="Subject" />}
        />
        {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={disabled}
          sx={{ mt: 3, mb: 2 }}
        >
          Upload
        </Button>
        <Grid container>
          <Grid item xs>
            <Typography component="p" variant="p" id="success"></Typography>

            {/* <Link href="#" variant="body2">
                Forgot password?
              </Link> */}
          </Grid>
          <Grid item>
            {/* <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link> */}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Upload;
