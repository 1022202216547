import './Player.css';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import { Button, Rating } from '@mui/material';

import Axios from 'axios';

function Player() {
    const location = useLocation()
    const { from } = location.state

    const [trueRating, setTrueRating] = useState(0);

    useEffect(() => {
            Axios.get(process.env.REACT_APP_API_URL + "/api/auth", {
                withCredentials: true, 
              }).then((response) => {
                if (response.data.includes("User authenticated as")) {
                    let userEmail = response.data.split(":")[1];
                    Axios.post(process.env.REACT_APP_API_URL + "/api/getUserRating", { videoId: from.id, userEmail: userEmail }).then((response) => {
                    setTrueRating(response.data.split(",")[1])
                    if(trueRating !== 0){
                    document.getElementById("error").innerHTML = "You rated this video a " + response.data.split(",")[0] + " out of 5.";
                    }
                });
                }
              });
    });

    const handleRating = (rating) => {
        Axios.get(process.env.REACT_APP_API_URL + "/api/auth", {
            withCredentials: true, 
          }).then((response) => {
            if (response.data.includes("User authenticated as")) {
              let userEmail = response.data.split(":")[1];
              Axios.post(process.env.REACT_APP_API_URL + "/api/rateVideo", { videoId: from.id, rating: rating, userEmail: userEmail }).then((response) => {
                if(response.data === "success"){
                    document.getElementById("error").innerHTML = "Successfully rated!"
                    //run get rating function
                } else {
                    document.getElementById("error").innerHTML = response.data;
                }
              });
            } else {
              document.getElementById("error").innerHTML = response.data;
              return;
            }
          });

    }

    return (
        <div className="videoContainer">
            <Box sx={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <iframe className="video" src={"https://www.youtube.com/embed/" + from.link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <Box
                    sx={{
                        width: "50%"
                    }}>
                    <Typography component="h1" variant="h4">{from.title}</Typography>

                    <Typography component="p" variant="p">{from.dateadded} | {from.maxPoints === 0 ? "Not yet rated." : <Rating name="half-rating" readOnly value={ trueRating !== 0 ? parseFloat(trueRating) : from.points * 5 / from.maxPoints} precision={0.1} />}</Typography>
                    <Divider sx={{margin: "10px"}} variant="middle" />
                    <Typography component="p" variant="p">{from.description}</Typography>
                    <Divider sx={{margin: "10px"}} variant="middle" />
                    <Typography component="h2" variant="h6">How was the quality of this video?</Typography>
                    <Box>
                    <Button
                    onClick={() => {handleRating(1)}}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}>
                        Poor
                    </Button>
                    <Button
                    variant="contained"
                    onClick={() => {handleRating(2)}}
                    sx={{ mt: 3, mb: 2 }}>
                        Fair
                    </Button>
                    <Button
                    variant="contained"
                    onClick={() => {handleRating(3)}}
                    sx={{ mt: 3, mb: 2 }}>
                        Average
                    </Button>
                    <Button
                    variant="contained"
                    onClick={() => {handleRating(4)}}
                    sx={{ mt: 3, mb: 2 }}>
                        Good
                    </Button>
                    <Button
                    variant="contained"
                    onClick={() => {handleRating(5)}}
                    sx={{ mt: 3, mb: 2 }}>
                        Excellent
                    </Button>
                    </Box>
                    <Typography id="error"></Typography>
                </Box>
            </Box>
        </div>
    );
}

export default Player;
