import './Videos.css';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider, Rating } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Autocomplete } from '@mui/material';

import {
  Link,
} from "react-router-dom";


import Axios from 'axios'

const colors = ["#ffe180", "#b3faff", "#bfffb3", "#ffd4f9", "#d7d4ff"]

function Videos() {

  const [keywords, setKeywords] = useState("");
  const [filter, setFilter] = useState("most recent");
  const [videoList, setVideoList] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedSubjectInput, setSelectedSubjectInput] = useState({id: 0, label: "Select a Subject"});



  useEffect(() => {
    getSubjects();
  }, []);


  const getSubjects = () => {
    Axios.get(process.env.REACT_APP_API_URL + "/api/getSubjects").then((response) => {
      let tempArray = [{id: 0, label: "Select a Subject"}];
      for (var i = 0; i < response.data.length; i++) {
        const newObject = {};
        delete Object.assign(newObject, response.data[i], { ['label']: response.data[i]['name'] })['name'];
        tempArray.push(newObject);
      }
      setSubjects(tempArray);
    });
  }

  const searchVideos = () => {
    Axios.post(process.env.REACT_APP_API_URL + "/api/getReviewedVideos", { filter: filter, selectedSubject: selectedSubject }).then((response) => {
      var tempArray = response.data;
      for (var i = 0; i < tempArray.length; i++) {
        if (tempArray[i].title.toLowerCase().includes(keywords.toLowerCase())) {
          tempArray[i].link = tempArray[i].link.split('/watch?v=')[1];

          let dt = tempArray[i].dateadded;
          let brokenDate = dt.split("T")[0].split("-");
          let recDate = brokenDate[1] + "/" + brokenDate[2] + "/" + brokenDate[0];

          let brokenTime = dt.split("T")[1].split(".")[0].split(":");

          let hours = parseInt(brokenTime[0]);
          let half = "";

          hours -= 5;

          if (hours <= 0) {
            hours += 12;
            half = "PM"
          } else if (hours <= 12) {
            half = "AM"
          } else {
            half = "PM"
            hours -= 12;
          }

          let newDT = "Uploaded at " + hours + ":" + brokenTime[1] + ", " + recDate;

          tempArray[i].dateadded = newDT;
        } else {
          tempArray.splice(i, 1);
          i--;
        }
      }
      setVideoList(tempArray);
      console.log(videoList)

    });
  }

  return (
    <div className="videoWrapper">
      <Typography component="h1" variant="h4">
        Videos
      </Typography>
      <Divider sx={{ margin: "10px" }} variant="middle" />

      <Box sx={{ display: "flex" }}>
        <div className="stackKeywords">
          <TextField
            margin="normal"
            id="keywords"
            label="Keywords"
            name="keywords"
            autoComplete="keywords"
            sx={{mt: 0}}
            autoFocus
            onChange={(e) => {
              setKeywords(e.target.value);
            }}
          />
          <FormControl>
            <InputLabel>Filter</InputLabel>
            <Select
              value={filter}
              label="Filter"
              onChange={(e) => {
                setFilter(e.target.value)
              }}
            >
              <MenuItem value={"most recent"}>Most Recent</MenuItem>
              <MenuItem value={"highest rated"}>Highest Rated</MenuItem>
              <MenuItem value={"subject a to z"}>Subject A to Z</MenuItem>
              <MenuItem value={"subject z to a"}>Subject Z to A</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 3 }}
            onClick={searchVideos}
          >
            Search
          </Button>
        </div>
        <div style={{ width: "50%" }}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disablePortal
            options={subjects}
            sx={{ width: 300 }}
            value={selectedSubjectInput}
            onChange={(e, newValue) => {
              setSelectedSubjectInput(newValue);
              setSelectedSubject(newValue.label)
            }}
            renderInput={(params) => <TextField {...params}
              />}     
                     
          />
          <Typography variant="h6"sx={{m: 1}}>Common Subjects</Typography>
          {
            subjects.map((subject, index) =>
              subject.label.includes("Algebra") || subject.label.includes("Chemistry") || subject.label.includes("Biology") || subject.label.includes("Precalculus") ?
                <button className="subjectColored" id={subject.label} onClick={() => {
                  setSelectedSubject(subject.label)
                  setSelectedSubjectInput({id: 0, label: subject.label})
                }} key={index}>{subject.label}</button> : null
            )
          }
        </div>
      </Box>

      {
        videoList.length > 0 ? 
          videoList.map((video, index) =>
          <Box key={index}
            sx={{
              borderTop: 2,
              borderColor: "lightGray"
            }}>
            <Link className="videoResult" to="/videos/play" state={{ from: video }}>
              <Box>
                <Typography component="h1" variant="h5">{video.title} | <button className="subjectColored" style={{ backgroundColor: colors[(index + 1) % 5] }}>{video.subject}</button></Typography>
                <Typography component="p" variant="p">{video.dateadded}  |  {video.maxPoints === 0 ? "Not yet rated." : <Rating name="half-rating" readOnly defaultValue={video.points * 5 / video.maxPoints} precision={0.1} />}</Typography>
                <Divider sx={{ margin: "10px" }} variant="middle" />
                <Typography variant="caption" gutterBottom >{video.description.substring(0, 100) + "..."}</Typography>
              </Box>
              <img className="thumbnail" src={`https://img.youtube.com/vi/${video.link}/maxresdefault.jpg`}></img>
            </Link>
          </Box>
        ) : 
        <h2>Hmm, we couldn't find anything for that search. Maybe try a different search, or make a video yourself!</h2>
      }

    </div>
  );
}

export default Videos;
