import './Login.css';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  Link,
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Axios from 'axios'

function Login() {

  const [logEmail, setLogEmail] = useState("");
  const [logPassword, setLogPassword] = useState("");
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(undefined);

  const handleLogin = (e) => {
    e.preventDefault();
      Axios.post(process.env.REACT_APP_API_URL + "/api/loginUser", { email: logEmail, password: logPassword }, { withCredentials: true }).then((response) => {
        if (response.data.includes("success")) {
          setDisabled(true);
          setError("");
          document.getElementById("success").innerHTML = response.data;
          
      } else {
          setError(response.data);
          return false;

      }
      }).then(() => {
        window.location.reload(true);
      });
  }

    return (
      <div className="submissionOuterContainer">
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box method="post" component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {
                setLogEmail(e.target.value);
            }}
            error={error !== "" ? true : false}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => {
                setLogPassword(e.target.value);
            }}
            error={error !== "" ? true : false}
            helperText={error}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={disabled}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
              <Typography component="p" variant="p" id="success"></Typography>

                <Link to="/resetPassword" variant="body2">
                  Forgot password?
            </Link>
            
              </Grid>
              <Grid item>
                {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
          </Box>
      </div>
    );
}

export default Login;