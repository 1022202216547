import './Admin.css';
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { Button } from '@mui/material';
import Axios from 'axios'
import Box from '@mui/material/Box';
import { Rating } from '@mui/material';

import {
  Link,
} from "react-router-dom";

function Admin() {

  const [videoList, setVideoList] = useState([]);

const getVideos = () => {
    Axios.get(process.env.REACT_APP_API_URL + "/api/getNotReviewedVideos").then((response) => {
      var tempArray = response.data;
      for(var i = 0; i < tempArray.length; i++){
          tempArray[i].link = tempArray[i].link.split('/watch?v=')[1];
  
          let dt = tempArray[i].dateadded;
          let brokenDate = dt.split("T")[0].split("-");
          let recDate = brokenDate[1] + "/" + brokenDate[2] + "/" + brokenDate[0];
  
          let brokenTime = dt.split("T")[1].split(".")[0].split(":");
  
          let hours = parseInt(brokenTime[0]);
          let half = "";
  
          hours -= 5;
  
          if(hours <= 0){
            hours += 12;
            half = "PM"
          } else if (hours <= 12){
            half = "AM"
          } else {
            half = "PM"
            hours -= 12;
          }
  
          let newDT = "Uploaded at " + hours + ":" + brokenTime[1] + ", " + recDate;
  
          tempArray[i].dateadded = newDT;
        
      }
      setVideoList(tempArray);
    })
}

const approve = (id) => {
  Axios.post(process.env.REACT_APP_API_URL + "/api/approve", { id: id }).then((response) => {
    
  });

}

const getUsers = () => {
    
}

  return (
      <div className="adminWrapper">
        <Typography component="h1" variant="h4">
        Admin Console
      </Typography>
      <Divider sx={{margin: "10px"}} variant="middle" />
      <Typography component="h1" variant="h4">
        Unreviewed Videos
      </Typography>
      <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 3 }}
          onClick={getVideos}
        >
          Search
        </Button>
        {
        videoList.map((video, index) => 
          <Box key={index}
          sx={{
            borderTop: 2,
            borderColor: "lightGray"
          }}>
          <Link  className="videoResult" to="/videos/play" state={{ from: video }}>
            <Box>
            <Typography component="h1" variant="h5">{video.title}</Typography>
            <Typography component="p" variant="p">{video.dateadded}  |  {video.maxPoints === 0 ? "Not yet rated." : <Rating name="half-rating" readOnly defaultValue={video.points * 5 / video.maxPoints} precision={0.1} />}</Typography>
            <Divider sx={{margin: "10px"}} variant="middle" />
            <Typography variant="caption" gutterBottom >{video.description.substring(0,100) + "..."}</Typography>
            </Box>
            <iframe className="video" src={"https://www.youtube.com/embed/" + video.link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <Button onClick={() => {approve(video.id)}}>Approve</Button>
          </Link>
          </Box>
        )
      }
        <Divider sx={{margin: "10px"}} variant="middle" />

        <Typography component="h1" variant="h4">
        Users
      </Typography>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 3 }}
          onClick={""}
        >
          Search
        </Button>
      </div>
  );
}

export default Admin;
