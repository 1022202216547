import './PasswordReset.css';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  Link,
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Axios from 'axios'

function PasswordReset() {

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(undefined);

  const handleReset = (e) => {
    e.preventDefault();

    if(password !== confirmPassword){
      setError("Passwords do not match.");
      return false;
    } else if(password.length < 8){
      setError("Passwords must be at least 8 characters.");
    }

      Axios.post(process.env.REACT_APP_API_URL + "/api/resetPassword/:passwordCode", { password: password, token: window.location.href.substring(window.location.href.lastIndexOf('/') + 1) }, { withCredentials: true }).then((response) => {
        if (response.data.includes("success")) {
          setDisabled(true);
          setError("");
          document.getElementById("success").innerHTML = response.data;
          
      } else {
          setError(response.data);
          return false;

      }
      });
  }

    return (
      <div className="submissionOuterContainer">
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box method="post" component="form" onSubmit={handleReset} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              autoComplete="password"
              autoFocus
              onChange={(e) => {
                setPassword(e.target.value);
            }}
            error={error !== "" ? true : false}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              autoComplete="confirmPassword"
              autoFocus
              onChange={(e) => {
                setConfirmPassword(e.target.value);
            }}
            error={error !== "" ? true : false}
            helperText={error}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={disabled}
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
            <Grid container>
              <Grid item xs>
              <Typography component="p" variant="p" id="success"></Typography>
              </Grid>

            </Grid>
          </Box>
      </div>
    );
}

export default PasswordReset;