import './ResendConfirmation.css';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Axios from 'axios'

const theme = createTheme();

function ResendConfirmation() {

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [disabled, setDisabled] = useState(false);

    const handleResend = (e) => {
        e.preventDefault();

        if(disabled){
            return;
        }

        if (!email.includes("@stxavier")) {
            setError("You must enter an email address licensed under the @stxavier domain.")
            return false;
        }

        Axios.post(process.env.REACT_APP_API_URL + "/api/resendConfirmation", { email: email }).then((response) => {
            console.log(response.data)
            if (response.data.includes("success")) {
                setDisabled(true);
                setError("");
                document.getElementById("success").innerHTML = response.data;
            } else {
                setError(response.data);
            }
        });
    }

    return (
        <div className="submissionOuterContainer">
            <Typography component="h1" variant="h5">
                Resend Confirmation Email
            </Typography>
            <Box component="form" onSubmit={handleResend} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    error={error !== "" ? true : false}
                    helperText={error}                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={disabled}
                >
                    Send Confirmation Email
                </Button>
                
                <Grid container>
                    <Grid item xs>
                        <Typography component="p" variant="p" id="success"></Typography>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default ResendConfirmation;
